$('.slider-home').slick({
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    nextArrow: '<div class="slick-next"><i class="fas fa-angle-right"></i></div>',
    prevArrow: '<div class="slick-prev"><i class="fas fa-angle-left"></i></div>',
    dots: true,
    pauseOnHover: true,
    fade: true
});
$('.partner-slide').slick({
  slidesToShow: 4,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: true,
  nextArrow: '<div class="slick-next"><i class="fas fa-angle-right"></i></div>',
  prevArrow: '<div class="slick-prev"><i class="fas fa-angle-left"></i></div>',
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2
      }
    }
  ]
});
$('.news').slick({
    slidesToShow: 2.5,
    autoplay: true,
    infinite:true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    pauseOnHover: true,
    responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ]
});
$(document).on("ready", function(){
  $(window).on("scroll", function(){
  	var scroll = $(window).scrollTop();
	  if (scroll > 100) {
	    $(".header .navbar .navbar-brand img").css("max-width" , 75);
      $(".header .navbar .navbar-collapse").css({
        "padding": 5 ,
        "padding-left":24,
        "background-color" : "#cb6b1d"
      });
      $(".header .navbar .navbar-collapse .navbar-nav .nav-item.active .nav-link").css({
        "color" : "#151945",
        "border-bottom-color" : "#151945"
      });
      if ($(window).width() < 768) {
        $(".header .navbar").css("background-color", "#cb6b1d");
      }
      else{
        $(".header .navbar").css("background-color", "transparent");
      }
	  }
	  else{
		  $(".header .navbar .navbar-brand img").css("max-width" , 125); 
      $(".header .navbar .navbar-collapse").css({
        "padding": 16 ,
        "padding-left":20,
        "background-color" : "#151945",
      });
      $(".header .navbar .navbar-collapse .navbar-nav .nav-item.active .nav-link").css({
        "color" : "#cb6b1d",
        "border-bottom-color" : "#cb6b1d"
      });
      if ($(window).width() < 768) {
        $(".header .navbar").css("background-color", "#151945");
      }
      else{
        $(".header .navbar").css("background-color", "transparent");
      }
	  }
  })
})